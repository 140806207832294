import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from './../Header';
// import Footer from "./../Footer";

import { uploadDataBranch } from './../../../actions/webRedActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './../../../public/scss/Assessments.scss';

// import dataDummy from './../dataDummy';
import { getAllAssessments,getScoreCardData } from './../../../actions/webRedActions';
import {accessToken, getYear} from './../../../actions/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
//import { getBaseUrl } from './../../../../actions/utility';
import { getBaseUrl } from './../../../actions/utility';
import {
    Button,
    Container,
    Row,
    Col,
    Collapse,
    Card,
    Spinner,
    CardBody,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';
import  $  from "jquery";
import Report from './PrintPdf';
import MultiPage from './anwers/reports/MultiPage';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
let user;
class ScoreCardsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedYear:getYear(),
            collapseState : [true, true, true, true],
            showReportModal : false,
            name:'',
            downloadLoader:false,
            employeeId:''
       
        };
    }

    componentDidMount() {
        //alert('hi:'+this.props.fromAdmin);
        // this.setState({
        //     data:this.props.scoreCard.comptStats
        // })
        //console.log("Faheem here:",this.props.userFetchedProfile);

        if(this.props.fromAdmin==true)
        {
          //console.log("user data from admin ",this.props.userFetchedProfile)
          this.state.name = this.props.userFetchedProfile.NAME;
          console.log("profile name:",this.state.name)
          this.state.employeeId = this.props.userFetchedProfile.ID_NUMBER;
          //this.props.getScoreCardData(this.props.userFetchedProfile.ID_NUMBER,'2020');
          this.props.getScoreCardData(this.props.userFetchedProfile.ID_NUMBER,getYear());
        }
        else{
          user= localStorage.getItem('pagCorUserProfile')
          if(user)
          { 
            user = JSON.parse(user)
            console.log('user profile',user)
            this.state.name = user.NAME;
            this.state.employeeId = user.ID_NUMBER;
            //this.props.getScoreCardData(user.ID_NUMBER,'2020');
            this.props.getScoreCardData(user.ID_NUMBER,getYear());
    
          }
          else {
            //alert("hi")
            window.localStorage.clear();
            window.location.href="/#/login";
            window.location.reload();
          }
        }
    }

    componentWillReceiveProps(nextProps, thisProps) {
        if(this.props.getScoreCardDataSet != nextProps.getScoreCardDataSet) {
            console.log("see here",nextProps.getScoreCardDataSet.result);
            this.setState({
              scoreCard:nextProps.getScoreCardDataSet.result,
              comCount:typeof nextProps.getScoreCardDataSet.result !== "undefined" ? nextProps.getScoreCardDataSet.result.comptCount : 0,
              contPer: typeof nextProps.getScoreCardDataSet.result !== "undefined" ? nextProps.getScoreCardDataSet.result.comptPerc : 0,
              data: typeof nextProps.getScoreCardDataSet.result !== "undefined" ? nextProps.getScoreCardDataSet.result.comptStats : undefined
            })
          }
        if(this.props.isLoadingCardList != nextProps.isLoadingCardList) {
            this.setState({data:undefined})
        }
    }
    getData=(year)=>{
        console.log('yyyyyyyyyy1111');
        console.log(year);
        this.setState({selectedYear:year,data:undefined})
        
        if(this.props.fromAdmin==true)
        {
            this.props.getScoreCardData(this.props.userFetchedProfile.ID_NUMBER,year);
        }
        else{
            if(user !=null)
                this.props.getScoreCardData(user.ID_NUMBER,year);
            else {
                window.localStorage.href="/#/login";
                window.location.reload();
            }
        }
      
      }
    getScore =(keyword)=>{
        if(keyword=='Rarely')
        {
            return 1
        }
        else if(keyword=='Sometimes'){
            return 2
        }
        else if(keyword=='Often'){
            return 3
        }
        else if(keyword=='Always'){
            return 4
        }
        else{
            return '--'
        }
    }
    pxToMm = (px) => {
        return Math.floor(px/document.getElementById('myMm').offsetHeight);
    };
      
    mmToPx = (mm) => {
        return document.getElementById('myMm').offsetHeight*mm;
    };
    render() {
        
        // const { competencies, subComptStatus, type, subOrdinateIdentity } = this.props;
        const { collapseState,competencies } = this.state;

        // console.log(competencies);

        return (
        <div>  
    <div className={'container'}>
                    <Row style={{paddingTop:41}}>
                    <Col  onClick={()=>{this.getData('2019')}} className={'yearCard'} style={{backgroundColor:this.state.selectedYear=='2019'?'white':'rgb(246 251 255)',color:this.state.selectedYear=='2019'?"black":"#cdd1d4",borderColor:this.state.selectedYear=='2019'&&'#007bff',borderRightWidth:this.state.selectedYear=='2019'?1:0}} md={{size: 2}}>
                        
                        <h5>2019</h5>
                    </Col>
                    
                    <Col onClick={()=>{this.getData('2020')}}  className={'yearCard'} style={{backgroundColor:this.state.selectedYear=='2020'?'white':'rgb(246 251 255)',color:this.state.selectedYear=='2020'?"black":"#cdd1d4",borderColor:this.state.selectedYear=='2020'&&'#007bff',borderLeftWidth:this.state.selectedYear=='2020'?1:0}} md={{size: 2}}>
                    
                        <h5>2020</h5>
                    </Col>
                    
                    <Col onClick={()=>{this.getData('2021')}}  className={'yearCard'} style={{backgroundColor:this.state.selectedYear=='2021'?'white':'rgb(246 251 255)',color:this.state.selectedYear=='2021'?"black":"#cdd1d4",borderColor:this.state.selectedYear=='2021'&&'#007bff',borderLeftWidth:this.state.selectedYear=='2021'?1:0}} md={{size: 2}}>
                    
                        <h5>2021</h5>
                    </Col>
                    <Col onClick={()=>{this.getData('2024')}}  className={'yearCard'} style={{backgroundColor:this.state.selectedYear=='2024'?'white':'rgb(246 251 255)',color:this.state.selectedYear=='2024'?"black":"#cdd1d4",borderColor:this.state.selectedYear=='2024'&&'#007bff',borderLeftWidth:this.state.selectedYear=='2024'?1:0}} md={{size: 2}}>
                    
                    <h5>2024</h5>
                </Col>

                    </Row>
                    </div>
            { this.state.data && ( this.state.selectedYear =='2019' || this.state.selectedYear == '2020') ? 
            
                <div>
                    <div className={'container'}>
                    <Row style={{paddingTop:41}}>
                    <Col className={'totalCard'} md={{size: 6}}>
                        <p>TOTAL  REQUIRED COMPETENCIES</p>
                        <h4>{this.state.comCount}</h4>
                    </Col>
                    <Col  className={'totalCard'} md={{size: 6}}>
                        <p>TOTAL SCORE</p>
                        <h4>{this.state.contPer}%</h4>
                    </Col>
                    </Row>
                    {
                    this.props.fromAdmin == true && this.state.selectedYear == '2020'? 
                    <Button onClick={()=>{
                        this.setState({
                            showReportModal:true
                        });
                        
                    }}>Report</Button>
                    :null    
                    }
                    </div>
                <div className="AssessmentList" style={{padding: '0 0 5vh 0', background: '#f6fbff'}}>

            <Container  style={{textAlign: 'left'}}>
                <div>
                {
                    
                    this.state.data && this.state.data.length > 0 && this.state.data.map((comp, key) => 
                        
                    

                            <Row   className="mainList Scorecard " key={key} style={{paddingBottom: '1vh'}}>
                                <Col md={{size: 12}} className="subList">
                                    <Row className="heading">
                                        <Col md={5}><h4>{comp.compt_title} COMPETENCIES</h4></Col>
                                        <Col md={1}><p>{"Self Rating"} </p></Col>
                                        <Col md={1}><p>{"Supervisor Rating"} </p></Col>
                                        <Col md={1}><p>{"Average Score"} </p></Col>
                                        <Col md={1}><p>{"RPL"} </p></Col>
                                        <Col md={1}><p>{"D.M.E"} </p></Col>
                                        <Col md={1}><p><b>{comp.percentage==null?"N/A":comp.percentage+"%"}</b> </p></Col>
                                    </Row>
                                
                                    {
                                        //console.log(comp.subCompt),
                                    comp.subCompt&&
                                            
                                            Array.isArray(comp.subCompt)==true?
                                            comp.subCompt.map((subComp,keySUb)=>{
                                                //  console.log(keySUb,subComp)
                                                return(
                                                <div key={key+"-"+keySUb}>
                                                <Row className="subcom">
                                                    <Col md={5}>{subComp.sub_compt_title}</Col>
                                                    <Col md={1}>{subComp.stats_data[subComp.sub_compt_keyword+"_INC"]=='null'?'N/A':subComp.stats_data[subComp.sub_compt_keyword+"_INC"]}</Col>
                                                    <Col md={1}>{subComp.stats_data[subComp.sub_compt_keyword+"_SUP"]=="null"?'N/A':subComp.stats_data[subComp.sub_compt_keyword+"_SUP"]}</Col>
                                                    <Col md={1}>{subComp.stats_data[subComp.sub_compt_keyword+"_AVE"]=="null"?"N/A":subComp.stats_data[subComp.sub_compt_keyword+"_AVE"]}</Col>
                                                    <Col md={1}>{subComp.stats_data[subComp.sub_compt_keyword+"_RPL"]=='null'?"N/A":subComp.stats_data[subComp.sub_compt_keyword+"_RPL"]}</Col>
                                                    <Col md={1}>{subComp.stats_data[subComp.sub_compt_keyword+"_GAP"]=="null"?"N/A":subComp.stats_data[subComp.sub_compt_keyword+"_GAP"]}</Col>
                                                <Col md={1}>{subComp.precentage=="null"?"N/A":Math.round(subComp.precentage)+"%"}</Col>
                                                    <div className={'collapIcon'}>
                                                        <Button  size="sm" color="primary" outline
                                                            onClick={(e) => {
                                                                var tempCol = collapseState;
                                                                tempCol[key+"-"+keySUb] = !tempCol[key+"-"+keySUb];
                                                                this.setState({
                                                                    collapseState : tempCol
                                                                })
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className="expandIcon" icon={!collapseState[key+"-"+keySUb] ? faPlus : faMinus} size="1x"/>
                                                            {
                                                                !collapseState[key+"-"+keySUb] ? '' : ''
                                                            }
                                                        </Button>
                                                    </div>
                                                </Row>    
                                                <Collapse className="subList" isOpen={collapseState[key+"-"+keySUb]}>
                                                        {
                                                            subComp.questions[0].status=='error'?
                                                            <Row key={key} className="question">
                                                            <Col md={12} style={{textAlign:'center',color:'red',backgroundColor:'#f9f9f9',paddingTop:12,paddingBottom:12,paddingLeft:15,paddingRight:15}}>{'No record found'}</Col>
                                                        
                                                            {/* <Col md={1}></Col> */}
                                                        </Row>
                                                            :
                                                            Object.entries(subComp.questions[0].result).map((qes,key)=>{
                                                            console.log(qes[1].answers[0])
                                                        
                                                                return(
                                                                    qes[1].answers?
                                                                    <Row key={key} className="question">
                                                                        <Col md={5}>{qes[1].question_title}</Col>
                                                                        <Col md={1}>{qes[1].answers.emloyee_answer==0?"--":this.getScore(qes[1].answers.emloyee_answer)}</Col>
                                                                        <Col md={1}>{qes[1].answers.sup_answer==0?"--":this.getScore(qes[1].answers.sup_answer)}</Col>
                                                                        <Col md={1}></Col>
                                                                        <Col md={1}></Col>
                                                                        <Col md={1}></Col>
                                                                        {/* <Col md={1}></Col> */}
                                                                    </Row>
                                                                    :
                                                                    <Row key={key} className="question">
                                                                    <Col md={12} style={{textAlign:'center',color:'red',backgroundColor:'#f9f9f9',paddingTop:12,paddingBottom:12,paddingLeft:15,paddingRight:15}}>{'No record found'}</Col>
                                                                
                                                                    {/* <Col md={1}></Col> */}
                                                                </Row>
                                                                )
                                                            })
                                                        }
                                                </Collapse>
                                                </div>
                                                )    
                                            })
                                            
                                            
                                            :
                                            comp.subCompt&&
                                            Object.entries(comp.subCompt).map((subComp,keySUb)=>{
                                                    //  console.log(keySUb,subComp)
                                                    return(
                                                    <div key={key+"-"+keySUb}>
                                                    <Row className="subcom">
                                                        <Col md={5}>{subComp[1].sub_compt_title}</Col>
                                                        <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_INC"]=='null'?'N/A':subComp[1].stats_data[subComp[1].sub_compt_keyword+"_INC"]}</Col>
                                                        <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_SUP"]=="null"?'N/A':subComp[1].stats_data[subComp[1].sub_compt_keyword+"_SUP"]}</Col>
                                                        <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_AVE"]=="null"?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword+"_AVE"]}</Col>
                                                        <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_RPL"]=='null'?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword+"_RPL"]}</Col>
                                                        <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword+"_GAP"]=="null"?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword+"_GAP"]}</Col>
                                                    <Col md={1}>{subComp[1].precentage=="null"?"N/A":Math.round(subComp[1].precentage)+"%"}</Col>
                                                        <div className={'collapIcon'}>
                                                            <Button  size="sm" color="primary" outline
                                                                onClick={(e) => {
                                                                    var tempCol = collapseState;
                                                                    tempCol[key+"-"+keySUb] = !tempCol[key+"-"+keySUb];
                                                                    this.setState({
                                                                        collapseState : tempCol
                                                                    })
                                                                }}
                                                            >
                                                                <FontAwesomeIcon className="expandIcon" icon={!collapseState[key+"-"+keySUb] ? faPlus : faMinus} size="1x"/>
                                                                {
                                                                    !collapseState[key+"-"+keySUb] ? '' : ''
                                                                }
                                                            </Button>
                                                        </div>
                                                    </Row>    
                                                    <Collapse className="subList" isOpen={collapseState[key+"-"+keySUb]}>
                                                            {
                                                                subComp[1].questions[0].status=='error'?
                                                                <Row key={key} className="question">
                                                                <Col md={12} style={{textAlign:'center',color:'red',backgroundColor:'#f9f9f9',paddingTop:12,paddingBottom:12,paddingLeft:15,paddingRight:15}}>{'No record found'}</Col>
                                                            
                                                                {/* <Col md={1}></Col> */}
                                                            </Row>
                                                                :
                                                                Object.entries(subComp[1].questions[0].result).map((qes,key)=>{
                                                                console.log(qes[1].answers[0])
                                                            
                                                                    return(
                                                                        qes[1].answers?
                                                                        <Row key={key} className="question">
                                                                            <Col md={5}>{qes[1].question_title}</Col>
                                                                            <Col md={1}>{qes[1].answers.emloyee_answer==0?"--":this.getScore(qes[1].answers.emloyee_answer)}</Col>
                                                                            <Col md={1}>{qes[1].answers.sup_answer==0?"--":this.getScore(qes[1].answers.sup_answer)}</Col>
                                                                            <Col md={1}></Col>
                                                                            <Col md={1}></Col>
                                                                            <Col md={1}></Col>
                                                                            {/* <Col md={1}></Col> */}
                                                                        </Row>
                                                                        :
                                                                        <Row key={key} className="question">
                                                                        <Col md={12} style={{textAlign:'center',color:'red',backgroundColor:'#f9f9f9',paddingTop:12,paddingBottom:12,paddingLeft:15,paddingRight:15}}>{'No record found'}</Col>
                                                                    
                                                                        {/* <Col md={1}></Col> */}
                                                                    </Row>
                                                                    )
                                                                })
                                                            }
                                                    </Collapse>
                                                    </div>
                                                    )    
                                                })
                                                
                                            }
                            
                                </Col>
                            </Row>
                
                    )
                }
                </div>
            </Container>

        </div>
    
                </div>
                :
                this.state.data && this.state.selectedYear >= '2021' ? 
                <div>
                    {console.log('working1')}
                     <div className={'container'}>
                        <Row style={{paddingTop:41}}>
                        <Col className={'totalCard'} md={{size: 6}}>
                            <p>TOTAL REQUIRED COMPETENCIES</p>
                            <h4>{this.state.comCount}</h4>
                        </Col>
                        <Col  className={'totalCard'} md={{size: 6}}>
                            <p>TOTAL SCORE</p>
                            <h4>{this.state.contPer}%</h4>
                        </Col>
                        </Row>
                        {
                        this.props.fromAdmin == true && (this.state.selectedYear == '2020' || this.state.selectedYear == '2021') ? 
                        <Button onClick={()=>{
                            this.setState({
                                showReportModal:true
                            });
                            
                        }}>Report</Button>
                        :null    
                        }
                    </div>
                    <div className="AssessmentList" style={{padding: '0 0 5vh 0', background: '#f6fbff'}}>
                        <Container  style={{textAlign: 'left'}}>
                            <div>
                            {
                                this.state.data && this.state.data.length > 0 && this.state.data.map((comp, key) =>
                                    comp.subCompt &&(
                                    <Row   className="mainList Scorecard " key={key} style={{paddingBottom: '1vh'}}>
                                        <Col md={{size: 12}} className="subList">
                                            <Row className="heading">
                                                <Col md={5}><h4>{comp.compt_title} COMPETENCIES</h4></Col>
                                                <Col md={1}><p>{"Score"} </p></Col>
                                                <Col md={1}><p>{"Cut-off Scores"} </p></Col>
                                                <Col md={1}><p>{"D.M.E"} </p></Col>
                                                <Col md={1}><p><b>{comp.percentage==null?"N/A":comp.percentage+"%"}</b> </p></Col>
                                            </Row>
                                            {
                                            comp.subCompt && Array.isArray(comp.subCompt) == true ? 
                                                comp.subCompt.map((subComp,keySUb)=>{
                                                    //  console.log(keySUb,subComp)
                                                    let keyWord = subComp.sub_compt_keyword.toLowerCase()
                                                    return(
                                                    <div key={key+"-"+keySUb}>
                                                    <Row className="subcom">
                                                        <Col md={5}>{subComp.sub_compt_title}</Col>
                                                        <Col md={1}>{subComp.stats_data[keyWord+"_score"]=="null"?"N/A":subComp.stats_data[keyWord+"_score"]+"%"}</Col>
                                                        <Col md={1}>{subComp.stats_data[keyWord+"_rpl"]=='null'?"N/A":subComp.stats_data[keyWord+"_rpl"]+"%"}</Col>
                                                        <Col md={1}>{subComp.stats_data[keyWord+"_gap"]=="null"?"N/A":subComp.stats_data[keyWord+"_gap"]}</Col>
                                                        {/* <Col md={1}>{subComp.precentage=="null"?"N/A":Math.round(subComp.precentage)+"%"}</Col> */}
                                                    </Row>
                                                    </div>
                                                    )
                                                })
                                                :
                                                comp.subCompt&&
                                                Object.entries(comp.subCompt).map((subComp,keySUb)=>{
                                                        //  console.log(keySUb,subComp)
                                                        return(
                                                            <div key={key+"-"+keySUb}>
                                                                <Row className="subcom">
                                                                    <Col md={5}>{subComp[1].sub_compt_title}</Col>
                                                                    <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_score"]=="null"?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_score"]+"%"}</Col>
                                                                    <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_rpl"]=='null'?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_rpl"]+"%"}</Col>
                                                                    <Col md={1}>{subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_gap"]=="null"?"N/A":subComp[1].stats_data[subComp[1].sub_compt_keyword.toLowerCase()+"_gap"]}</Col>
                                                                    {/* <Col md={1}>{subComp[1].precentage=="null"?"N/A":Math.round(subComp[1].precentage)+"%"}</Col> */}
                                                                </Row>
                                                            </div>
                                                        )
                                                })
                                            }
                                        </Col>
                                    </Row>)
                                   
                                )
                            }
                            </div>
                        </Container>
                    </div>
                </div>
                :
                <Col md={{size: 12}} style={{textAlign: 'center', padding: '30vh 0'}}>
                    <Spinner color="primary" />
                </Col>
            }
            <Modal isOpen={this.state.showReportModal}  size='lg' centered={true} scrollable={true} onExit={()=>{
                console.log('modal exited');
            }} style={{maxWidth: 850}}>
                <ModalHeader>{this.state.name+"'s Report"}</ModalHeader>
                <ModalBody >
                    <Report reportData={this.state.data} userName={this.state.name} year={this.state.selectedYear}/>
                </ModalBody>
                
                <ModalFooter>
                   
                    <Button color="primary" onClick={()=>{
                        // this.setState({
                        //     downloadLoader:true
                        // })
                        // const input = document.getElementById('multiPage');
                        // const inputHeightMm = this.pxToMm(input.offsetHeight);
                        // const a4WidthMm = 210;
                        // const a4HeightMm = 297; 
                        // const a4HeightPx = this.mmToPx(a4HeightMm); 
                        // const numPages = inputHeightMm <= a4HeightMm ? 1 : Math.floor(inputHeightMm/a4HeightMm) + 1;
                        
                        // html2canvas(input,{ height: input.offsetHeight+(numPages*30) })
                        //     .then((canvas) => {
                        //     const imgData = canvas.toDataURL('image/png');
                        //     var pdf = '';
                        //     // Document of a4WidthMm wide and inputHeightMm high
                        //     if (inputHeightMm > a4HeightMm) {
                        //         // elongated a4 (system print dialog will handle page breaks)
                        //         pdf = new jsPDF('p', 'mm', [inputHeightMm+(30*numPages), a4WidthMm]);
                        //     } else {
                                
                        //         // standard a4
                        //         pdf = new jsPDF();
                        //     }
                        //     var fileName = this.state.name+' Report';
                        //     console.log('yes');
                        //     pdf.addImage(imgData, 'PNG', 0, 0);
                        //     var ths = this;
                        //     pdf.save(`${fileName}.pdf`,{returnPromise:true}).then((res)=>{
                        //         ths.setState({
                        //             downloadLoader:false
                        //         })
                        //     });
                        // });
                        window.open(getBaseUrl()+"api/admin/download/employee/pdf/report/"+this.state.employeeId+"/"+this.state.selectedYear+"?access_token="+accessToken());
                    }}>
                        {
                            this.state.downloadLoader ? 
                            <Spinner size="sm" color='light' style={{marginRight:5}}/>
                            :null
                        }
                        Download Report</Button>
                    <Button color="primary" disabled={this.state.downloadLoader} onClick={()=>{
                        
                        var mywindow = window.open('', 'PRINT', `
                        width=${window.screen.availWidth}, 
                        height=${window.screen.availHeight}, 
                        `);

                        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
                        mywindow.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" onload="alert("hi")"></link>')
                        mywindow.document.write('</head><body >');
                        //mywindow.document.write('<h1>' + document.title  + '</h1>');
                        mywindow.document.write (document.getElementById('printReport').innerHTML);
                        mywindow.document.write('</body></html>');

                        mywindow.document.close(); // necessary for IE >= 10

                        mywindow.focus(); // necessary for IE >= 10*/
                        //var interVal
                        // setTimeout(function(){
                        //    // mywindow.print();
                        //     //mywindow.close();
                        // },100)
                        //var cssnum = mywindow.document.styleSheets.length;
                        var ti = setInterval(function() {
                            if(mywindow.document.styleSheets.length) {
                                mywindow.print();
                                mywindow.close();
                                clearInterval(ti);
                            }   
                        }, 10);

                    }}>Print Report</Button>
                    <Button color="secondary" onClick={()=>{
                        this.setState({
                            showReportModal:false
                        })
                    }}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

ScoreCardsList.propTypes = {
    // uploadDataBranch: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    // triggerNextPinSuccess : state.webRed.triggerNextPinSuccess,
    getScoreCardDataSet : state.webRed.getScoreCardDataSet,
    isLoadingCardList : state.webRed.isLoadingCardList
})

export default connect( mapStatesToProps, { getScoreCardData })( ScoreCardsList );