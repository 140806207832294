import React, { Component } from "react";
import Header from './../Header';
// import Footer from "./../Footer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './../../../public/scss/login.scss';

import { sendValidateEmpId, loginWithVerificationCode } from './../../../actions/webRedActions';
import { autoFocusNextField } from './../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form, FormGroup, Label, Input, FormText,
    Spinner
  } from "reactstrap";

import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class VerificationCodeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            verificationId : ["", "", "", ""],
            verificationIdString : "",
            verificationCodeError : "",
            isLoading: false
            // enableNextBtn : false

        };
    }

    componentDidMount() {
        
        autoFocusNextField("boxFieldForm"); // auto focus utility method called
        
    }

    componentWillReceiveProps(nextProps, thisProps) {
        if(this.props.triggeredLoginAction != nextProps.triggeredLoginAction) {
            // console.log(nextProps.triggerNextPinSuccess)
            if(nextProps.triggeredLoginAction.data.status == "error") {
                this.setState({
                    isLoading : false,
                    verificationCodeError : nextProps.triggeredLoginAction.data.message
                })
            }
        }
    }

    handleChange = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var verificationIdTemp = this.state.verificationId;
        verificationIdTemp[index] = value;

        this.setState({
            [name] : verificationIdTemp
        }, () => {
            console.log(this.state.verificationId);

            var verification_id = this.convertIdToString(this.state.verificationId);
            this.setState({
                verificationIdString : verification_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                // if(verification_id.length == 4){
                    
                // }
                console.log(this.state.verificationIdString)
            })

        })
        
        if(this.state.verificationCodeError != "") {
            this.setState({
                verificationCodeError : ""
            })
        }
        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        
    }

    convertIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }
    handleClick = () => {
        this.props.sendDataToParent(false); // Send data to parent
      };
    render() {

    const {verificationId, verificationIdString, verificationCodeError, isLoading} = this.state;
    const { userEmail, employeeId, isAdmin,adminType } = this.props;

    console.log('isAdmin',isAdmin)

    return (
      <div className="VerificationCodeForm" style={{padding: '10vh 0', textAlign: 'center'}}>

        <h3>Check your email</h3>
        <hr />
        <Row>
            <Col>
                <Form>
                    <p>We have just sent you an email with a 4 digits verification code on your PAGCOR email id: <strong>{userEmail}</strong></p>
                    <FormGroup className="boxFieldForm" style={{margin: '40px 0 20px 0'}}>
                        
                        <Input type="text" bsSize="lg" maxLength="1" name="verificationId" id="verificationId1"
                            value={verificationId[0]}
                            onChange={evt => this.handleChange(evt, 0)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="verificationId" id="employeeI2" 
                            value={verificationId[1]}
                            onChange={evt => this.handleChange(evt, 1)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="verificationId" id="employeeI3"
                            value={verificationId[2]}
                            onChange={evt => this.handleChange(evt, 2)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="verificationId" id="employeeI4"
                            value={verificationId[3]}
                            onChange={evt => this.handleChange(evt, 3)} />

                    </FormGroup>
                    {
                        verificationCodeError && verificationCodeError != "" ?
                            <p style={{color: 'red'}}>
                                <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} />
                                {verificationCodeError}
                            </p>
                        : null
                    }
                </Form>
                {
                    verificationIdString.length < 4 ? <p>
                    <span onClick={this.handleClick}
                    style={{color: 'blue', cursor: 'pointer',textDecoration: 'underline'}}>Try with another employee id</span>
                </p> : null
                }
               
            </Col>
        </Row>
        <Row>
            <Col md={{size: '12'}} style={{textAlign: 'center'}}>

                {
                    verificationIdString.length == 4 ?
                        <Button style={{margin: '30px 0 0 0'}} color={"primary"} size="lg"
                            onClick={(e) => {
                                this.setState({
                                    isLoading : true
                                }, () => {
                                    // send and validate verification code against employee id action
                                    this.props.loginWithVerificationCode(employeeId, verificationIdString, isAdmin, adminType);
                                })
                            }}
                            disabled={isLoading}
                        >
                            Login
                            {
                                isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                            }
                        </Button>
                    : null
                }

            </Col>
            
            <Col style={{padding: '10vh 0'}}>
                <hr />
                <p>
                    I did not get an email. <br/>
                    <span onClick={(e) => {
                        // Resend verification code to user for second time or later
                        this.props.sendValidateEmpId(employeeId, true);
                    }}
                    style={{color: 'blue', cursor: 'pointer'}}>Please resend my verification code</span>
                </p>
            </Col>
        </Row>
      </div>
    );
  }
}

VerificationCodeForm.propTypes = {
    loginWithVerificationCode: PropTypes.func.isRequired,
    sendValidateEmpId: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    triggeredLoginAction : state.webRed.triggeredLoginAction
})

export default connect( mapStatesToProps, { loginWithVerificationCode, sendValidateEmpId })( VerificationCodeForm );