import React from 'react'
import { Input } from 'reactstrap';
import { getYear, updateYear } from '../../actions/utility';

function UpdateYear() {
  return (
    <>
    <Input
     type="select" name="update_getyear" id="update_getyear" className='my-4' onChange={(event) => {
         console.log("sel val:", event.target.value)
         
         updateYear(event.target.value);
        console.log('ssss',getYear());
    }}>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024">2024</option>
        
      </Input>
          </>
  )
}

export default UpdateYear