import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Header from '../../Header';
// import Footer from "./../Footer";

import {
    fetchCompleteReportsData,
    getAdditionalData,
    getMainGroupsData,
    fetchSubGroupsOrLocationsOrSOG,
    getAssesstDeptsHierarchy,
    getAssesstDivHierarchy,
    getAssesstSectionHierarchy,
    getAssesstPCHierarchy,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy,
    getLocationPCHierarchy,
    fetchLocationBranches,
    getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,
    getAssessBranchPCHierarchy
} from '../../../../../src/actions/webRedActions';

// import { getEmployeeProfile } from '../../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import { getBaseUrl, accessToken, getYear, generateToken } from './../../../../actions/utility';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form,
    FormGroup,
    Label,
    Input,
    Collapse,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Spinner,
    Progress
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

// import readXlsxFile from 'read-excel-file';
import EmployeeProfile from "./EmployeeProfile";
import EmployeeSearchForm from "./EmployeeSearchForm";
import AddEmployeeProfile from "./AddEmployeeProfile";
import AdminHeader from './../AdminHeader';
import SideMenu from './../sidMenu/SideMenu';
import { getAdminUserData } from "../../../../../src/actions/utility";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars,faHome,faUsers } from '@fortawesome/free-solid-svg-icons';
import 'react-pro-sidebar/dist/css/styles.css';
import { param } from "jquery";
import { ResponsiveBar, Bar } from "@nivo/bar";

class EmpReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerTitle: "Reports",
            competencies: [],
            completedata: '',
            completeBranchData: '',
            completeDeptData: '',
            completeDivisionData: '',
            completeSectionData: '',

            isOpen: [true, true, true, true],

            divisionList: [],
            sectionList: [],
            branchList: [],
            deptList: [],
            pcList: [],

            DIVISION: '',
            SECTION: '',
            BRANCH: '',
            DEPT: '',
            PC: '',
            reportSelectedYear: "",
            isLoading: false,
            payClass: 0,
            mainGroupsDataArr: [],
            loadingSubGroups: false,
            loadingDepart: [],
            departsData: [],
            divisionData: [],
            deptDivs: [],
            deptDivLoader: false,
            groupId: "",
            deptId: "",
            divisionId: "",
            sectionId: "",
            sectionLoader: false,
            sectionData: [],
            subGroups: [],
            locations: [],
            locationLoading: false,
            divisionLoading: false,
            departsData: [],
            locationBranches: [],
            divisionData: [],
            sectionLoading: [],
            sectionData: [],
            pcData: [],
            pcLoading: false,
            subGroupId: "",
            branchId: "",
            pcId: "",
            locationId: ""
        };
    }

    componentWillMount() {

        // get all stats data
        // this.props.fetchCompleteReportsData();


        // if (getYear() > '2020') {
        //     //this.props.getAdditionalData();
        //     this.getAllDistinctData('PC');
        // }
        // else {
        //     // this.getAllDistinctData('DIVISION');
        //     // this.getAllDistinctData('SECTION');
        //     // this.getAllDistinctData('BRANCH');
        //     //this.getAllBranchesData();
        //     // this.getAllDistinctData('DEPT');
        //     this.getAllDistinctData('PC');
        // }
        this.props.getMainGroupsData("");


    }

    getAllBranchesData = () => {
        axios.get(getBaseUrl() + 'api/branches/get?access_token=' + accessToken())
            .then((res) => {
                console.log(res);
                if (res.data.result) {
                    this.setState({
                        branchList: res.data.result
                    }, () => {
                        console.log(this.state.branchList)
                        this.setState({
                            BRANCH: this.state.branchList[0].branch_id
                        }, () => {
                            //this.getSpecificFilterData('branch', this.state.BRANCH);
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    componentWillReceiveProps(nextProps, thisProps) {
        if (this.props.getEmpReportsSuccess != nextProps.getEmpReportsSuccess) {
            // console.log();
            try {
                var completeData = nextProps.getEmpReportsSuccess.data;
                if (completeData) {
                    this.setState({
                        completedata: completeData,
                        isLoading: false,
                        competencies: Object.keys(completeData).map((comp, key) => {
                            return ({
                                name: comp,
                                subcompetencies: Object.keys(completeData[comp]).map((subcomp, key2) => {
                                    return ({
                                        name: subcomp
                                    })
                                })
                            })
                        })
                    }, () => {
                        console.log(this.state.completedata)
                    })
                }
            } catch (e) {
                confirmAlert({
                    title: 'Error',
                    message: "There's some error in loading Reports...",
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => { }
                        }
                    ]
                })
            }

        }
        if (this.props.additionalData != nextProps.additionalData) {
            console.log("additionalData:", nextProps.additionalData.data);
            this.setState({
                branchList: nextProps.additionalData.data.branches,
                divisionList: nextProps.additionalData.data.divisions,
                pcList: nextProps.additionalData.data.payClass,
                sectionList: nextProps.additionalData.data.sections,
                deptList: nextProps.additionalData.data.departments,

            })
        }
        if (this.props.mainGroupsData != nextProps.mainGroupsData) {
            this.setState({ mainGroupsDataArr: nextProps.mainGroupsData.data }, () => {
                console.log('set state works here', this.state.mainGroupsDataArr);
            })
        }
    }

    updateValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    updateValuePayClass = (thisValue) => {
        // console.log(e.target.value)
        var payClass = thisValue;

        this.setState({
            isLoading: true
        })
        var year = getYear();

        axios.get(getBaseUrl() + 'api/admin/payclass/data?access_token=' + accessToken() + '&pay_class=' + payClass + '&type=branch&year=' + year)
            .then((res) => {
                // console.log(res);
                if (res.data.status == "success") {

                    this.setState({
                        isLoading: false
                    }, () => {

                        try {
                            var fetchedData = res.data.result ? res.data.result[payClass] : null;
                            // console.log(fetchedData)
                            // if(fetchedData && fetchedData.DEPT){
                            //     this.setState({
                            //         deptList : fetchedData.DEPT
                            //     }, () => {
                            //         this.setState({
                            //             DEPT : this.state.deptList[0].DEPT
                            //         }, () => {
                            //             this.getSpecificFilterData('DEPT', this.state.DEPT);
                            //         })
                            //     })
                            // }
                            // if(fetchedData && fetchedData.DIVISION){
                            //     this.setState({
                            //         divisionList : fetchedData.DIVISION
                            //     }, () => {
                            //         this.setState({
                            //             DIVISION : this.state.divisionList[0].DIVISION
                            //         }, () => {
                            //             this.getSpecificFilterData('DIVISION', this.state.DIVISION);
                            //         })
                            //     })
                            // }
                            // if(fetchedData && fetchedData.SECTION){
                            //     this.setState({
                            //         sectionList : fetchedData.SECTION
                            //     }, () => {
                            //         this.setState({
                            //             SECTION : this.state.sectionList[0].SECTION
                            //         }, () => {
                            //             this.getSpecificFilterData('SECTION', this.state.SECTION);
                            //         })
                            //     })
                            // }
                            if (fetchedData && fetchedData.branches) {
                                this.setState({
                                    branchList: fetchedData.branches
                                }, () => {
                                    this.setState({
                                        BRANCH: this.state.branchList.length > 0 ? this.state.branchList[0].branch_id : ''
                                    }, () => {
                                        this.state.payClass = payClass;
                                        console.log('in the log message :', this.state.BRANCH)
                                        if (getYear() < '2021') {
                                            if (this.state.BRANCH != '')
                                                this.getDivisions(payClass, this.state.BRANCH);
                                            else
                                                this.setState({ divisionList: [] });
                                        }
                                        else {
                                            if (this.state.BRANCH != '')
                                                this.getPASDivisions(payClass, this.state.BRANCH);
                                            else
                                                this.setState({ divisionList: [] });
                                        }

                                        this.getSpecificFilterData('branch', this.state.BRANCH);
                                    })
                                })
                            }
                        } catch (e) {
                            console.log(e);
                        }

                    })

                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false
                })
            })
    }

    updateValueBranch = (e) => {
        console.log('this is the value:', e.target.selectedIndex);
        var year = getYear();
        if (year < '2021')
            this.getDivisions(this.state.payClass, this.state.branchList[e.target.selectedIndex].branch_id);
        else
            this.getPASDivisions(this.state.payClass, this.state.branchList[e.target.selectedIndex].branch_id)

        this.setState({
            [e.target.name]: this.state.branchList[e.target.selectedIndex].branch_id
        }, () => {
            this.getSpecificFilterData('branch', this.state.BRANCH);
        })
    }
    getSections = (payClass, branchId, division) => {
        var year = getYear();
        axios.get(getBaseUrl() + 'api/admin/payclass/data?acess_token=' + accessToken() + '&type=section&branch_id=' + branchId + '&pay_class=' + payClass + '&division=' + division + '&year=' + year, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.status == 200 && res.data.result) {
                    console.log("this is the res payClass:", res.data.result[payClass]);

                    this.setState({
                        sectionList: res.data.result[payClass].SECTION
                    }, () => {
                        this.setState({
                            SECTION: this.state.sectionList != null && this.state.sectionList.length > 0 ? year < '2021' ? this.state.sectionList[0].SECTION : this.state.sectionList[0].section_id : null
                        }, () => {
                            if (this.state.SECTION)
                                this.getSpecificFilterData('SECTION', this.state.SECTION);
                            else {
                                this.setState({
                                    isLoading: false,
                                    // completeSectionData : ''
                                });
                            }
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getPASSections = (payClass, branchId, division) => {
        var year = getYear();
        axios.post(getBaseUrl() + 'api/admin/branch/sections?acess_token=' + accessToken() + '&branch_id=' + branchId + '&pc=' + payClass + '&division=' + division + '&year=' + year, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.status == 200 && res.data.result) {
                    console.log("this is the res payClass:", res.data.result);

                    this.setState({
                        sectionList: res.data.result
                    }, () => {
                        this.setState({
                            SECTION: this.state.sectionList != null && this.state.sectionList.length > 0 ? year < '2021' ? this.state.sectionList[0].SECTION : this.state.sectionList[0].section_id : null
                        }, () => {
                            if (this.state.SECTION)
                                this.getSpecificFilterData('SECTION', this.state.SECTION);
                            else {
                                this.setState({
                                    isLoading: false,
                                    // completeSectionData : ''
                                });
                            }
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getPASDivisions = (payClass, branchId) => {
        this.setState({ divisionLoading: true });
        this.state.branchId = branchId;
        var year = getYear();
        axios.post(getBaseUrl() + 'api/admin/branch/divisions?acess_token=' + accessToken() + '&branch_id=' + branchId + '&pc=' + payClass + '&year=' + year, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.status == 200 && res.data.result) {
                    console.log("this is the res Faheem:", res.data.result);
                    this.setState({
                        divisionList: res.data.result
                    }, () => {
                        this.setState({
                            DIVISION: this.state.divisionList != null && this.state.divisionList.length > 0 ? year < '2021' ? this.state.divisionList[0].DIVISION : this.state.divisionList[0].division_id : null
                        }, () => {
                            console.log(this.state.DIVISION);
                            if (this.state.DIVISION != null) {
                                console.log('go for fetch the sections');
                                this.getSpecificFilterData('DIVISION', this.state.DIVISION);
                                this.getPASSections(payClass, branchId, this.state.DIVISION);
                            }
                            else {
                                this.setState({
                                    sectionList: [],
                                    completeDivisionData: '',
                                    // completeSectionData: '',
                                    isLoading: false
                                });
                            }
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getDivisions = (payClass, branchId) => {
        this.setState({ divisionLoading: true });
        this.state.branchId = branchId;
        var year = getYear();
        axios.get(getBaseUrl() + 'api/admin/payclass/data?acess_token=' + accessToken() + '&type=division&branch_id=' + branchId + '&pay_class=' + payClass + '&year=' + year, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                if (res.status == 200 && res.data.result) {
                    console.log("this is the res Faheem:", res.data.result);
                    this.setState({
                        divisionList: res.data.result[payClass].DIVISION
                    }, () => {
                        this.setState({
                            DIVISION: this.state.divisionList != null && this.state.divisionList.length > 0 ? year < '2021' ? this.state.divisionList[0].DIVISION : this.state.divisionList[0].division_id : null
                        }, () => {
                            console.log(this.state.DIVISION);
                            if (this.state.DIVISION != null) {
                                this.getSpecificFilterData('DIVISION', this.state.DIVISION);
                                this.getSections(payClass, branchId, this.state.DIVISION);
                            }
                            else {
                                this.setState({
                                    sectionList: [],
                                    completeDivisionData: '',
                                    // completeSectionData: '',
                                    isLoading: false
                                });
                            }
                        })
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    subGroups = (groupId) => {
        // alert("Hi");
        this.setState({
            loadingSubGroups: true, divisionData: [],
            groupId: groupId, deptDivs: [],
            subGroups: [], locations: [], sogs: [],
            loacations: [], divisionData: [], sectionData: [],
            pcData: [], locations: [], locationBranches: [],
            departsData: [], pcData: [], divisionData: [], sectionData: [],
            subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
            deptId: "", pcId: "", statisticsData: []
        })
        let ths = this;
        this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ subGroups: res.result, loadingSubGroups: false })
            }
        })
    }
    sog = (groupId) => {
        // alert("Hi");
        this.setState({
            loadingDepart: true, divisionData: [], groupId: groupId, deptDivs: [],
            subGroups: [], locations: [], sogs: [], pcData: [], locations: [], locationBranches: [],
            subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
            deptId: "", pcId: "", statisticsData: []
        })
        let ths = this;
        this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ sogs: res.result, loadingDepart: false })
            }
        })
    }
    locations = (groupId) => {
        // alert("Hi");
        this.setState({
            loadingSubGroups: true, divisionData: [], groupId: groupId, deptDivs: [],
            sogs: [], subGroups: [], locations: [], sogs: [],
            loacations: [], divisionData: [], sectionData: [], departsData: [],
            pcData: [], locationBranches: [],
            subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
            deptId: "", pcId: "", statisticsData: []
        })
        let ths = this;
        this.props.fetchSubGroupsOrLocationsOrSOG(groupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ locations: res.result, loadingSubGroups: false })
            }
        })
    }
    groupDeparts = (subGroupId) => {
        // alert("Hi");
        this.setState({
            departmentLoading: true, divisionData: [], subGroupId: subGroupId, deptDivs: [],
            locations: [], sogs: [], pcData: [], divisionData: [], sectionData: [],
            locationId: "", branchId: "", divisionId: "", sectionId: "",
            deptId: "", pcId: "", statisticsData: []
        })
        let ths = this;
        this.props.getAssesstDeptsHierarchy(subGroupId, function (res) {
            console.log("this is the sever res:", res)
            if (res != 0) {
                ths.setState({ departsData: res.result, departmentLoading: false })
            }
        })
    }
    getDivisions = (deptId) => {
        this.setState({
            divisionLoading: true, divisionData: [], pcData: [], sectionData: [], deptId: deptId,
            locationId: "", branchId: "", divisionId: "", sectionId: "", pcId: "",
            statisticsData: []
        })
        let ths = this;
        this.props.getAssesstDivHierarchy(deptId, this.state.subGroupId, function (res) {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ divisionData: res.result, divisionLoading: false })
            }
        })
    }
    getSections = (divId) => {
        this.setState({
            sectionLoading: true, divisionId: divId, pcData: [],
            locationId: "", branchId: "", sectionId: "", pcId: "",
            statisticsData: []
        })
        const { subGroupId, deptId } = this.state
        let ths = this;
        this.props.getAssesstSectionHierarchy(subGroupId, deptId, divId, function (res) {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ sectionData: res.result, sectionLoading: false })
            }
        })
    }
    getPC = (sectionId) => {
        this.setState({
            pcLoading: true, sectionId: sectionId, locationId: "", branchId: "", pcId: "",
            statisticsData: []
        })
        const { subGroupId, deptId, divisionId } = this.state
        let ths = this;
        this.props.getAssesstPCHierarchy(subGroupId, deptId, divisionId, sectionId, function (res) {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ pcData: res.result, pcLoading: false })
            }
        })
    }
    getlocationDivs = (locationId) => {
        this.setState({
            locationLoading: true, locationId: locationId, divisionData: [], pcData: [], sectionData: [],
            subGroupId: "", branchId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        let ths = this;
        this.props.getLocationDivsHierarchy(locationId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ divisionData: res.result, locationLoading: false })
            }
        })
    }
    getlocationSection = (divisionId) => {
        this.setState({
            sectionLoading: true, divisionId: divisionId, sectionData: [], pcData: [],
            subGroupId: "", branchId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId } = this.state;
        let ths = this;
        this.props.getLocationSectionHierarchy(locationId, divisionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ sectionLoading: false, sectionData: res.result })
            }
        })
    }
    getLocationPC = (sectionId) => {

        this.setState({
            pcLoading: true, sectionId: sectionId, pcData: [],
            subGroupId: "", branchId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId, divisionId } = this.state;
        let ths = this;
        this.props.getLocationPCHierarchy(locationId, divisionId, sectionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ pcData: res.result, pcLoading: false })
            }
        })
    }
    getBranches = (locId) => {
        this.setState({
            locationLoading: true, locationId: locId, locationBranches: [],
            subGroupId: "", branchId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId, divisionId } = this.state;
        let ths = this;
        this.props.fetchLocationBranches(locId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ locationLoading: false, locationBranches: res.result })
            }
        })

    }
    getBranchDiv = (branchId) => {
        this.setState({
            divisionLoading: true, branchId: branchId, divisionData: [], pcData: [], sectionData: [],
            subGroupId: "", divisionId: "", sectionId: "", pcId: "", deptId: "",
            statisticsData: []
        })
        const { locationId } = this.state
        //getAssessBranchDivsHierarchy
        let ths = this;
        this.props.getAssessBranchDivsHierarchy(locationId, branchId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ divisionLoading: false, divisionData: res.result })
            }
        })
    }
    getlocationBranchSection = (divisionId) => {
        this.setState({
            sectionLoading: true, divisionId: divisionId, sectionData: [], pcData: [],
            subGroupId: "", sectionId: "", pcId: "", deptId: "", statisticsData: []
        })
        const { locationId, branchId } = this.state;
        let ths = this;
        this.props.getAssessBranchSectionHierarchy(locationId, branchId, divisionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ sectionLoading: false, sectionData: res.result })
            }
        })
    }
    getLocationBranchPC = (sectionId) => {

        this.setState({
            pcLoading: true, sectionId: sectionId, pcData: [],
            subGroupId: "", deptId: "", pcId: "", statisticsData: []
        })
        const { locationId, divisionId, branchId } = this.state;
        let ths = this;
        this.props.getAssessBranchPCHierarchy(locationId, branchId, divisionId, sectionId, (res) => {
            console.log("this is the sever res for divisions:", res.result)
            if (res != 0) {
                ths.setState({ pcData: res.result, pcLoading: false })
            }
        })
    }

    updateDeptValue = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getSpecificFilterData('DEPT', this.state.DEPT);
        })
    }

    updateDivisionValue = (e) => {
        if (getYear() < '2021')
            this.getSections(this.state.payClass, this.state.BRANCH, e.target.value)
        else
            this.getPASSections(this.state.payClass, this.state.BRANCH, e.target.value);

        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getSpecificFilterData('DIVISION', this.state.DIVISION);
        })
    }

    updateSectionValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getSpecificFilterData('SECTION', this.state.SECTION);
        })
    }


    getSpecificFilterData = (itemValue, value) => {

        var params = '';
        value = encodeURIComponent(value);



        if (itemValue == 'branch') {
            params = "branch_id=" + value;
        }
        if (itemValue == 'DEPT') {
            params = "DEPT=" + value;
        }
        if (itemValue == 'DIVISION') {
            params = "DIVISION=" + value;
        }
        if (itemValue == 'SECTION') {
            params = "SECTION=" + value;
        }
        params += "&year=" + getYear();
        params += "&access_token=" + accessToken();
        this.setState({
            isLoading: true
        })
        console.log("this.state:", this.state);
        axios.get(getBaseUrl() + 'api/admin/assessment/statistics/all?' + params)
            .then((res) => {
                console.log(res);
                this.setState({
                    isLoading: true
                }, () => {
                    if (res.data.result) {

                        try {
                            if (itemValue == 'branch') {

                                this.setState({
                                    completeBranchData: res.data.result,
                                    isLoading: false
                                }, () => {
                                    console.log(this.state.completeBranchData)
                                })

                            }
                            if (itemValue == 'DEPT') {

                                this.setState({
                                    completeDeptData: res.data.result
                                }, () => {
                                    console.log(this.state.completeDeptData)
                                })

                            }
                            if (itemValue == 'DIVISION') {

                                this.setState({
                                    completeDivisionData: res.data.result,
                                    isLoading: false
                                }, () => {
                                    console.log(this.state.completeDivisionData)
                                })

                            }
                            if (itemValue == 'SECTION') {
                                this.setState({
                                    completeSectionData: res.data.result,
                                    isLoading: false
                                }, () => {
                                    console.log(this.state.completeSectionData)
                                })

                            }
                        } catch (e) {
                            console.log(e)
                        }

                    }
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false
                })
            })

    }

    // fetch all distinct values for any column
    getAllDistinctData = (col) => {
        var bodyFormData = new FormData();
        bodyFormData.set('col', col);
        bodyFormData.set('acess_token', accessToken());
        bodyFormData.set('year', getYear());

        axios.post(getBaseUrl() + 'api/admin/employee/column/data', bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                console.log(res);
                if (res.data.result) {

                    // if(col == 'DIVISION') {
                    //     this.setState({
                    //         divisionList : res.data.result
                    //     }, () => {
                    //         console.log(this.state.divisionList)
                    //         this.setState({
                    //             DIVISION : this.state.divisionList[0].DIVISION
                    //         }, () => {
                    //             this.getSpecificFilterData('DIVISION', this.state.DIVISION);
                    //         })
                    //     })
                    // }
                    // else if(col == 'SECTION') {
                    //     this.setState({
                    //         sectionList : res.data.result
                    //     }, () => {
                    //         console.log(this.state.sectionList)
                    //         this.setState({
                    //             SECTION : this.state.sectionList[0].SECTION
                    //         }, () => {
                    //             this.getSpecificFilterData('SECTION', this.state.SECTION);
                    //         })
                    //     })
                    // }
                    // if(col == 'BRANCH') {
                    //     this.setState({
                    //         branchList : res.data.result
                    //     }, () => {
                    //         console.log(this.state.branchList)
                    //         this.setState({
                    //             BRANCH : this.state.branchList[0].BRANCH
                    //         })
                    //     })
                    // }
                    // else if(col == 'DEPT') {
                    //     this.setState({
                    //         deptList : res.data.result
                    //     }, () => {
                    //         console.log(this.state.deptList)
                    //         this.setState({
                    //             DEPT : this.state.deptList[0].DEPT
                    //         }, () => {
                    //             this.getSpecificFilterData('DEPT', this.state.DEPT);
                    //         })
                    //     })
                    // }
                    if (col == 'PC') {
                        this.setState({
                            pcList: res.data.result
                        }, () => {
                            console.log(this.state.pcList)
                            this.setState({
                                PC: 'all'//this.state.pcList[0].PC
                            }, () => {
                                this.updateValuePayClass(this.state.PC);
                            })
                        })
                    }


                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSubmitFilter = (e) => {

        const { DIVISION, SECTION, BRANCH, DEPT, PC } = this.state;

        this.setState({
            competencies: []
        }, () => {

            this.props.fetchCompleteStats({
                DIVISION: DIVISION,
                SECTION: SECTION,
                BRANCH: BRANCH,
                DEPT: DEPT,
                PC: PC
            })

        })
    }
    loadDMEBarGraph = (keys, data) => {
        console.log(keys, "===", data)
        return (
            <ResponsiveBar
                data={data}
                keys={keys}
                indexBy="category"
                layout="horizontal"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                colors={{ scheme: 'set3' }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}

                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />
        )
    }
    fetchEmpReports = () => {
        if (this.state.reportSelectedYear != "") {
            this.setState({ applyFilterLoader: true, isLoading: true, statisticsData: [] })
            if (this.state.groupId == 1) {
                let filter = {
                    group_id: this.state.groupId,
                    dept_id: this.state.deptId,
                    div_id: this.state.divisionId,
                    section_id: this.state.sectionId,
                    sub_groupid: this.state.subGroupId,
                    pc_id: this.state.pcId,
                    acess_token: accessToken(),
                    year: this.state.reportSelectedYear,
                }
                console.log("This is the filter data:", filter)
                const queryString = new URLSearchParams(filter).toString();
                this.props.fetchCompleteReportsData(queryString)

            }
            if (this.state.groupId == 2 || this.state.groupId == 3) {
                let filter = {
                    group_id: this.state.groupId,
                    div_id: this.state.divisionId,
                    section_id: this.state.sectionId,
                    pc_id: this.state.pcId,
                    location_id: this.state.locationId,
                    branch_id: this.state.branchId,
                    year: this.state.reportSelectedYear,
                    acess_token: accessToken()
                }
                console.log("This is the filter data:", filter)
                const queryString = new URLSearchParams(filter).toString();
                this.props.fetchCompleteReportsData(queryString)

            }
            if(this.state.groupId == "") { //fetch the pagcor wide data
                let filter = {
                    group_id: "",
                    div_id: "",
                    section_id: "",
                    pc_id: "",
                    location_id: "",
                    branch_id: "",
                    year: this.state.reportSelectedYear,
                    acess_token: accessToken()
                }
                console.log("This is the filter data:", filter)
                const queryString = new URLSearchParams(filter).toString();
                this.props.fetchCompleteReportsData(queryString)
            }
        }
        else
            alert("Please select the year.")
    }
    render() {

        const { completedata, completeBranchData, completeDeptData,
            completeDivisionData, completeSectionData, competencies,
            isOpen, branch, isLoading, mainGroupsDataArr, loadingSubGroups,
            subGroups, departmentLoading, locations, locationLoading, departsData,
            divisionLoading, locationBranches, divisionData, sectionLoading, sectionData,
            pcLoading, pcData
        } = this.state;
        console.log("competencies", competencies);
        console.log("completeSectionData", completeSectionData);
        const dataArr = [
            { category: 'DME', matching: 20, exceeding: 30, deficient: 10 },
        ];

        const keysArr = ['deficient', 'matching', 'exceeding'];
        return (
            <div>
                {/*Side menu*/}

                <SideMenu />

                <AdminHeader title={this.state.headerTitle} style={{ marginLeft: 80 }} />

                {
                    isLoading ?
                        <div style={{
                            position: 'fixed',
                            zIndex: '9999',
                            width: '100%',
                            height: '100%',
                            left: 0,
                            top: 0,
                            background: 'rgba(255,255,255,0.5)'
                        }}>
                            <Spinner style={{ margin: '35vh 0' }} size="md" color="dark" />
                        </div>
                        : null
                }

                <Container className="AssessmentStatistics EmpReports" >

                    <Row style={{ background: '#d5eafb' }}>

                        {/* <Col md={{ size: 4 }} style={{ textAlign: 'right' }}>
                            <h5 style={{ fontSize: '18px', fontWeight: 'normal', lineHeight: '56px' }}>PAYCLASS COMPARISONS</h5>
                        </Col> */}
                        <Col md={{ size: 2 }}>
                            {/* <FormGroup>


                                {
                                    getYear() > '2020' ?
                                        <Input type="select" name="PC" id="PC" onChange={(e) => this.updateValuePayClass(e.target.value)}>
                                            <option value='all'>All</option>
                                            {
                                                this.state.pcList && this.state.pcList.map((data, key) =>
                                                    <option
                                                        key={key}
                                                        val={data.pc_id}
                                                    >
                                                        {data.pc_name}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                        :
                                        <Input type="select" name="PC" id="PC" onChange={(e) => this.updateValuePayClass(e.target.value)}>
                                            <option value='all'>All</option>

                                            {
                                                this.state.pcList && this.state.pcList.map((data, key) =>
                                                    <option
                                                        key={key}
                                                        val={data.PC}
                                                    >
                                                        {data.PC}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                }
                            </FormGroup> */}
                            <FormGroup>
                                <Label for="BRANCH">Main Group</Label>:
                                <Input type="select" name="main_group" id="main_group" onChange={(event) => {
                                    console.log("sel val:", event.target.value)
                                    if (event.target.value == 1) {
                                        this.subGroups(event.target.value);
                                    }
                                    else if (event.target.value == 2 || event.target.value == 3) {
                                        this.locations(event.target.value);
                                    }
                                    else if(event.target.value == ""){
                                        // this.setState({groupId:""})
                                        this.setState({
                                            loadingSubGroups: false, divisionData: [],
                                            groupId: "", deptDivs: [],
                                            subGroups: [], locations: [], sogs: [],
                                            loacations: [], divisionData: [], sectionData: [],
                                            pcData: [], locations: [], locationBranches: [],
                                            departsData: [], pcData: [], divisionData: [], sectionData: [],
                                            subGroupId: '', locationId: "", branchId: "", divisionId: "", sectionId: "",
                                            deptId: "", pcId: "", statisticsData: []
                                        })
                                    }
                                }}>
                                    {/* <option value="0">Select Main Group</option> */}
                                    <option value="">PAGCOR</option>
                                    {
                                        mainGroupsDataArr.length > 0 ?
                                            mainGroupsDataArr.map((group, index) =>
                                                <option value={group.group_id}>{group.group_name}</option>
                                            )
                                            : null
                                    }
                                </Input>
                            </FormGroup>
                            {
                                loadingSubGroups == true ?
                                    <Spinner color="primary" style={{
                                        width: '1.10rem',
                                        height: '1.10rem',
                                        marginTop: '-40px',
                                        float: 'right'
                                    }} />
                                    : null
                            }
                        </Col>
                        {
                            subGroups.length > 0 ?
                                <Col md={{ size: 2 }}>
                                    <>
                                        {
                                            subGroups.length > 0 ?
                                                <FormGroup>
                                                    <Label for="BRANCH">Sub Groups</Label>:
                                                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                        this.groupDeparts(e.target.value)
                                                    }}>
                                                        <option value="0">Select sub group</option>
                                                        {
                                                            subGroups.length > 0 ?
                                                                subGroups.map((sg, index) =>
                                                                    <option value={sg.sub_groupid}>{sg.sub_groupname}</option>
                                                                )
                                                                : null
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                :
                                                null
                                        }
                                    </>

                                    {
                                        departmentLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>
                                : null
                        }
                        {
                            locations.length > 0 ?
                                <Col md={{ size: 2 }}>
                                    <>
                                        {
                                            locations.length > 0 ?
                                                <FormGroup>
                                                    <Label for="BRANCH">Locations</Label>:
                                                    <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                                        if (this.state.groupId == 2)
                                                            this.getlocationDivs(e.target.value)
                                                        if (this.state.groupId == 3)
                                                            this.getBranches(e.target.value)
                                                    }}>
                                                        <option value="0">Select locations</option>
                                                        {
                                                            locations.length > 0 ?
                                                                locations.map((location, index) =>
                                                                    <option value={location.location_id}>{location.location_name}</option>
                                                                )
                                                                : null
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                :
                                                null
                                        }
                                    </>

                                    {
                                        locationLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>
                                : null
                        }
                        {
                            departsData.length > 0 ?
                                <Col md={{ size: 2 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Departments</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            this.getDivisions(e.target.value)
                                        }}>
                                            <option value="0">Select department</option>
                                            {
                                                departsData.length > 0 ?
                                                    departsData.map((department, index) =>
                                                        <option value={department.dept_id}>{department.dept_suffix}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        divisionLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }
                        {
                            locationBranches.length > 0 ?
                                <Col md={{ size: 2 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Branches</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            this.getBranchDiv(e.target.value)
                                        }}>
                                            <option value="0">Select branch</option>
                                            {
                                                locationBranches.length > 0 ?
                                                    locationBranches.map((branch, index) =>
                                                        <option value={branch.branch_id}>{branch.branch_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        divisionLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }
                        {
                            divisionData.length > 0 ?
                                <Col md={{ size: 2 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Divisions</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            if (this.state.groupId == 1)
                                                this.getSections(e.target.value)
                                            else if (this.state.groupId == 2)
                                                this.getlocationSection(e.target.value)
                                            else if (this.state.groupId == 3)
                                                this.getlocationBranchSection(e.target.value)
                                        }}>
                                            <option value="0">Select Division</option>
                                            {
                                                divisionData.length > 0 ?
                                                    divisionData.map((div, index) =>
                                                        <option value={div.division_id}>{div.division_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        sectionLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }
                        {
                            sectionData.length > 0 ?
                                <Col md={{ size: 2 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Sections</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            if (this.state.groupId == 1)
                                                this.getPC(e.target.value)
                                            else if (this.state.groupId == 2)
                                                this.getLocationPC(e.target.value)
                                            else if (this.state.groupId == 3)
                                                this.getLocationBranchPC(e.target.value)
                                        }}>
                                            <option value="0">Select Section</option>
                                            {
                                                sectionData.length > 0 ?
                                                    sectionData.map((section, index) =>
                                                        <option value={section.section_id}>{section.section_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                    {
                                        pcLoading == true ?
                                            <Spinner color="primary" style={{
                                                width: '1.10rem',
                                                height: '1.10rem',
                                                marginTop: '-40px',
                                                float: 'right'
                                            }} />
                                            : null
                                    }
                                </Col>

                                : null
                        }
                        {
                            pcData.length > 0 ?
                                <Col md={{ size: 2 }}>
                                    <FormGroup>
                                        <Label for="BRANCH">Job Grade</Label>:
                                        <Input type="select" name="main_group" id="main_group" onChange={(e) => {
                                            // alert(e.target.value)
                                            // this.getPC(e.target.value)
                                            this.setState({ pcId: e.target.value })
                                        }}>
                                            <option value="0">Select Job Grade</option>
                                            {
                                                pcData.length > 0 ?
                                                    pcData.map((pc, index) =>
                                                        <option value={pc.pc_id}>{pc.pc_name}</option>
                                                    )
                                                    : null
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                                : null
                        }
                        <Col md={{ size: 2 }}>
                            <FormGroup>
                                <Label for="BRANCH">Years</Label>:
                                <Input type="select" name="report_year" id="report_year" onChange={(e) => {
                                    console.log("this is year report value:", e.target.value);
                                    this.setState({
                                        reportSelectedYear: e.target.value
                                    });
                                }}>
                                    <option value="0">Select year</option>
                                    {/* <option value="2019">2019</option> */}
                                    {/* <option value="2020">2020</option> */}
                                    <option value="2021">2021</option>
                                    <option value="2024">2024</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={{ size: 2 }} style={{ maxWidth: "80px" }}>
                            <FormGroup style={{ marginTop: "80%" }}>
                                <Button size="sm" onClick={() => {
                                    this.fetchEmpReports()

                                }}>Search</Button>
                            </FormGroup>
                        </Col>
                        <Col md={{ size: 2 }}>
                            <FormGroup style={{ marginTop: "25%" }}>
                                <Button style={{ width: 200 }} size="sm" onClick={() => {

                                    //var year = new Date().getFullYear();
                                    if (this.state.reportSelectedYear != 0){
                                        const token = generateToken()
                                        const tokenConvert = encodeURIComponent(token)
                                        window.open(getBaseUrl() + 'api/admin/download/employees/excel/report/' + this.state.reportSelectedYear+'?access_token='+accessToken() + '&pass_token=' + tokenConvert)
                                    }else{
                                        alert('Please select valid year to download report.');
                                    }
                                }}>Download Employees Report</Button>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row style={{ display: "none" }}>
                        <Col md={{ size: 4 }}></Col>
                        <Col md={{ size: 1 }}>
                            <FormGroup>
                                <Label>PAGCOR</Label>
                            </FormGroup>
                        </Col>
                        {/* <Col md={{ size: 2 }}>
                            <FormGroup>
                                <Label for="BRANCH">BRANCH/DEPT</Label>

                                <Input type="select" name="BRANCH" id="BRANCH" onChange={(e) => this.updateValueBranch(e)}>
                                    {
                                        this.state.branchList && this.state.branchList.map((data, key) =>
                                            <option
                                                key={key}
                                                val={data.branch_id}
                                            >
                                                {data.branch_name}
                                            </option>
                                        )
                                    }
                                </Input>
                            </FormGroup>
                        </Col> */}

                        {/* <Col md={{size: 1}}>
                    <FormGroup>                        
                        <Label for="DEPT">DEPT</Label>
                        {
                            getYear() > '2020' ?
                            <Input type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateDeptValue(e)}>
                            {
                                this.state.deptList && this.state.deptList.map((data, key) =>
                                    <option
                                        key={key}
                                        value={data.dept_id}
                                    >
                                        {data.dept_name}
                                    </option>
                                )
                            }
                            </Input> 
                            :
                            <Input type="select" name="DEPT" id="DEPT" onChange={(e) => this.updateDeptValue(e)}>
                                {
                                    this.state.deptList && this.state.deptList.map((data, key) =>
                                        <option
                                            key={key}
                                            val={data.DEPT}
                                        >
                                            {data.DEPT}
                                        </option>
                                    )
                                }
                            </Input>
                        }
                    </FormGroup>
                </Col> */}

                        <Col md={{ size: 2 }}>
                            <FormGroup>
                                <Label for="DIVISION">DIVISION</Label>
                                {
                                    getYear() > '2020' ?
                                        <Input type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateDivisionValue(e)}>
                                            {
                                                this.state.divisionList && this.state.divisionList.map((data, key) =>
                                                    <option
                                                        key={key}
                                                        value={data.division_id}
                                                    >
                                                        {data.division_name}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                        :
                                        <Input type="select" name="DIVISION" id="DIVISION" onChange={(e) => this.updateDivisionValue(e)}>
                                            {
                                                this.state.divisionList && this.state.divisionList.map((data, key) =>
                                                    <option
                                                        key={key}
                                                        val={data.DIVISION}
                                                    >
                                                        {data.DIVISION}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                }
                            </FormGroup>
                        </Col>

                        <Col md={{ size: 2 }}>
                            <FormGroup>
                                <Label for="SECTION">SECTION</Label>
                                {
                                    getYear() > '2020' ?
                                        <Input type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateSectionValue(e)}>
                                            {
                                                this.state.sectionList && this.state.sectionList.map((data, key) =>
                                                    <option
                                                        key={key}
                                                        value={data.section_id}
                                                    >
                                                        {data.section_name}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                        :
                                        <Input type="select" name="SECTION" id="SECTION" onChange={(e) => this.updateSectionValue(e)}>
                                            {
                                                this.state.sectionList && this.state.sectionList.map((data, key) =>
                                                    <option
                                                        key={key}
                                                        val={data.SECTION}
                                                    >
                                                        {data.SECTION}
                                                    </option>
                                                )
                                            }
                                        </Input>
                                }
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        {/* <Col md={{size : 12}} className="text-left">
                    <p className="breadcrumbs">Comparison of <strong>Pagcor - Bacolod</strong></p>
                </Col> */}
                        <Col md={{ size: 12 }}>

                            {/* <Row className="text-left" style={{marginBottom: '10px'}}>
                        <Col md={{size: 2}}></Col>
                        <Col md={{size: 2}}>

                        </Col>
                        <Col md={{size: 2}}>
                        
                        </Col> 
                        <Col md={{size: 2}}></Col> 
                        <Col md={{size: 2}}></Col> 
                        <Col md={{size: 2}}></Col>
                    </Row> */}
                            {
                                competencies && competencies.length ?
                                    competencies.map((comp, key) =>
                                        comp.name !== 'totallPerc' ?
                                            <div key={key} className="text-left">

                                                <Row className="text-left">
                                                    <Col md={{ size: 12 }}>

                                                        <FontAwesomeIcon
                                                            className="toggleIconHere"
                                                            icon={isOpen[key] ? faMinus : faPlus} size="1x"
                                                            onClick={(e) => {
                                                                var is_open = isOpen;
                                                                is_open[key] = !is_open[key];
                                                                this.setState({
                                                                    isOpen: is_open
                                                                })
                                                            }}
                                                        />
                                                        <h5>{comp.name}</h5>
                                                    </Col>
                                                    {/* <Col md={{size: 2}}>{completedata[2019][comp.name].totallPerc}%</Col> */}
                                                    {/* <Col md={{size: 2}}>{completedata[2020][comp.name].totallPerc}%</Col>  */}
                                                    {/* <Col md={{size: 3}}></Col> */}
                                                </Row>
                                                <Collapse isOpen={isOpen[key]} className="text-left">
                                                    <Card style={{ border: 'none' }}>
                                                        <CardBody style={{ border: 0, padding: 0 }}>
                                                            {
                                                                comp.subcompetencies && comp.subcompetencies.length && comp.subcompetencies.map((subComp, key2) =>
                                                                    subComp.name !== 'totallPerc' ?
                                                                        <Row key={key2}>
                                                                            <Col md={{ size: 6 }}>{subComp.name}</Col>
                                                                            <Col md={{ size: 4 }}>{completedata[comp.name][subComp.name].totallPerc}%</Col>
                                                                            {/* <Col md={{ size: 2 }}>
                                                                                {
                                                                                    completeBranchData &&
                                                                                    completeBranchData?.[comp.name]?.[subComp.name] && completeBranchData?.[comp.name]?.[subComp.name]?.totallPerc + '%'
                                                                                }
                                                                            </Col>
                                                                            <Col md={{ size: 1 }}>
                                                                                {
                                                                                    completeDeptData &&
                                                                                    completeDeptData?.[comp.name]?.[subComp.name] && completeDeptData?.[comp.name]?.[subComp.name]?.totallPerc + '%'
                                                                                }
                                                                            </Col>
                                                                            <Col md={{ size: 2 }}>
                                                                                {
                                                                                    completeDivisionData &&
                                                                                    completeDivisionData?.[comp.name]?.[subComp.name] && completeDivisionData?.[comp.name]?.[subComp.name]?.totallPerc + '%'

                                                                                }
                                                                            </Col>
                                                                            <Col md={{ size: 2 }}>
                                                                                {
                                                                                    completeSectionData &&
                                                                                    completeSectionData?.[comp.name]?.[subComp.name] && completeSectionData?.[comp.name]?.[subComp.name]?.totallPerc + '%'

                                                                                }
                                                                            </Col> */}
                                                                            <Col md={{ size: 2 }}>
                                                                                <div style={{ width: '300px', height: '30px' }}>
                                                                                    <ResponsiveBar
                                                                                        data={[completedata[comp.name][subComp.name].DME]}
                                                                                        keys={keysArr}
                                                                                        indexBy="category"
                                                                                        layout="horizontal"
                                                                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                                                        padding={0.3}
                                                                                        colors={['#f47560', '#61cdbb', '#e8a838']}
                                                                                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                                        axisTop={null}
                                                                                        axisRight={null}
                                                                                        axisBottom={null}
                                                                                        axisLeft={null}
                                                                                        labelSkipWidth={12}
                                                                                        labelSkipHeight={12}
                                                                                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}

                                                                                        animate={true}
                                                                                        motionStiffness={90}
                                                                                        motionDamping={15}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        : null
                                                                )
                                                            }

                                                        </CardBody>
                                                    </Card>
                                                </Collapse>
                                            </div>
                                            : null
                                    )
                                    : null
                            }
                        </Col>
                    </Row>
                </Container>
            </div>


        );
    }
}

EmpReports.propTypes = {
    fetchCompleteReportsData: PropTypes.func.isRequired,
    getAdditionalData: PropTypes.func.isRequired,
    getMainGroupsData: PropTypes.func.isRequired,
    fetchSubGroupsOrLocationsOrSOG: PropTypes.func.isRequired,
    getAssesstDeptsHierarchy: PropTypes.func.isRequired,
    getAssesstDivHierarchy: PropTypes.func.isRequired,
    getAssesstSectionHierarchy: PropTypes.func.isRequired,
    getAssesstPCHierarchy: PropTypes.func.isRequired,
    getLocationDivsHierarchy: PropTypes.func.isRequired,
    getLocationSectionHierarchy: PropTypes.func.isRequired,
    getLocationPCHierarchy: PropTypes.func.isRequired,
    fetchLocationBranches: PropTypes.func.isRequired,
    getAssessBranchDivsHierarchy: PropTypes.func.isRequired,
    getAssessBranchSectionHierarchy: PropTypes.func.isRequired,
    getAssessBranchPCHierarchy: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    getEmpReportsSuccess: state.webRed.getEmpReportsSuccess,
    additionalData: state.webRed.additionalData,
    mainGroupsData: state.webRed.mainGroupsData,
})

export default connect(mapStatesToProps, {
    fetchCompleteReportsData,
    getAdditionalData,
    getMainGroupsData,
    fetchSubGroupsOrLocationsOrSOG,
    getAssesstDeptsHierarchy,
    getAssesstDivHierarchy,
    getAssesstSectionHierarchy,
    getAssesstPCHierarchy,
    getLocationDivsHierarchy,
    getLocationSectionHierarchy,
    getLocationPCHierarchy,
    fetchLocationBranches,
    getAssessBranchDivsHierarchy,
    getAssessBranchSectionHierarchy,
    getAssessBranchPCHierarchy
})(EmpReports);