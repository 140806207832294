import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css'; // importing bootstrap css for reactStrap

import $ from 'jquery';
import {getBaseUrl,accessToken,getYear, generateToken} from './actions/utility'
//code for check website active state/logout user when there is no activity untill session time
import * as Sentry from "@sentry/react";

import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://caaabefcdaaa4c558b98992ebb7b1312@o1076705.ingest.sentry.io/6078818",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  
var timeoutInMiliseconds = 1000 * 60 * 20;//20 minuts session for inactivity
// var timeoutInMiliseconds = 1000 * 60 * 1;
var timeoutId; 
 
function startTimer() { 
    // window.setTimeout returns an Id that can be used to start and stop a timer
    if(
        window.location.href.includes("/pagcor/talentmap") == false && 
        window.location.href.includes("/pagcor/comparision") == false &&
        window.location.href.includes("localhost") == false
    ) {
        console.log("start time in function")
        timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
    }
    //timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
}
 
function doInactive() {
    // does whatever you need it to actually do - probably signs them out or stops polling the server for info
    
    try {
        var userData = JSON.parse(window.localStorage.pagCorUserProfile);
        console.log('userData:',userData)
        const token = generateToken();
        $.ajax({
            url     : getBaseUrl()+'api/add/employee/logout/time',
            data    : "acess_token="+accessToken()+"&year="+getYear()+"&employee_id="+userData.ID_NUMBER,
            type    : "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorizedToken': token,
			'appidadmin':localStorage.getItem('app_id_session')
            },
            success : function(res) {
                window.localStorage.clear();
                window.location.href="/#/login";
                window.location.reload();
            }
        })
    }
    catch(err) {
        console.log("err:",err)
        window.localStorage.clear();
        window.location.href="/#/login";
        window.location.reload();
    }
}
function resetTimer() { 
//   console.log('in reset time'); 
  window.clearTimeout(timeoutId)
  startTimer();
}

$(document).ready(function() {
    let name = 'PAGCOR-App'
    let version = '2.2.1'
    console.log(`${name} v${version}`)
    const lastVersion = window.localStorage.getItem(`${name}-Version`)
    if(lastVersion !== version) {
        if(window.localStorage.length > 0 )
            window.localStorage.clear();

        console.log('New Version Available !')
        window.localStorage.setItem(`${name}-Version`, version)
        window.location.href="/#/login";
        window.location.reload(true);
    }
    // alert("34")
    console.log('app status:',process.env.NODE_ENV)
    //setupTimers();
    startTimer();
    $(document).on('keypress mousemove mousedown touchmove click scroll',function() {
        resetTimer();
    })
})
//end code window activity checker

var isDebug=true;
if( typeof process !=='undefined' && typeof process.env !=='undefined' && process.env.NODE_ENV === 'production')
{
    console.log= function(){}
}
if (isDebug === false)
{
    console.log= function(){}
}
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
