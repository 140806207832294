import React, { Component } from "react";
import Header from './../Header';
// import Footer from "./../Footer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import './../../../public/scss/login.scss';

import { sendValidateEmpId } from './../../../actions/webRedActions';
import { autoFocusNextField } from './../../../actions/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import userData from './../userData';

import {
    Button,
    Container,
    Row,
    Col,
    Media,
    Form, FormGroup, Label, Input, FormText,
    Spinner
  } from "reactstrap";

// import readXlsxFile from 'read-excel-file';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class EmployeedIdForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            // userInfo : {
            //     employee_id : '01-1234'
            // }, // dummy user data
            employeeId : ["", "", "-", "", "", "", ""],
            employeeIdString : "",
            employeeIdError : "",
            isLoading: false,
            // enableNextBtn : false

        };
    }
  
    componentDidMount() {

        autoFocusNextField("boxFieldForm"); // auto focus utility method called

    }

    componentWillReceiveProps(nextProps, thisProps) {
        if(this.props.triggerNextPinSuccess != nextProps.triggerNextPinSuccess) {
            // console.log(nextProps.triggerNextPinSuccess)
            if(nextProps.triggerNextPinSuccess.data.status == "error") {
                this.setState({
                    isLoading : false,
                    employeeIdError : nextProps.triggerNextPinSuccess.data.message
                })
            }
        }
    }

    handleChange = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;

        var employeeIdTemp = this.state.employeeId;
        employeeIdTemp[index] = value;

        this.setState({
            [name] : employeeIdTemp
        }, () => {
            // console.log(this.state.employeeId);

            var emp_id = this.convertEmpIdToString(this.state.employeeId);
            this.setState({
                employeeIdString : emp_id
            }, () => {
                // it means all inputs are done here we need to call action to verify
                
                if(emp_id.length == 7) {
                }
                // console.log(this.state.employeeIdString)
            })

        })

        if(this.state.employeeIdError != "") {
            this.setState({
                employeeIdError : ""
            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        
    }

    convertEmpIdToString = (empId) => {
        var stringEmpId = "";
        empId.map(val => {
            if(val && val != "" && val != undefined){
                stringEmpId += val;
            }
        })

        return stringEmpId;
    }

    render() {

    const {employeeId, employeeIdError, employeeIdString, isLoading} = this.state;

    return (
      <div className="EmployeedIdForm" style={{padding: '10vh 0', textAlign: 'center'}}>

        <h3>Welcome to PAGCOR's Assessments</h3>
        <hr />
        <Row>
            <Col>
                <Form>
                    <p>What is your employee ID NUMBER?</p>
                    <FormGroup className="boxFieldForm">
                        
                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId1"
                            value={employeeId[0]}
                            onChange={evt => this.handleChange(evt, 0)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId2" 
                            value={employeeId[1]}
                            onChange={evt => this.handleChange(evt, 1)} />

                        <Input type="text" bsSize="lg" value="-" maxLength="1" name="dash" id="dash" disabled />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId3"
                            value={employeeId[3]}
                            onChange={evt => this.handleChange(evt, 3)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId4"
                            value={employeeId[4]}
                            onChange={evt => this.handleChange(evt, 4)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId5"
                            value={employeeId[5]}
                            onChange={evt => this.handleChange(evt, 5)} />

                        <Input type="text" bsSize="lg" maxLength="1" name="employeeId" id="employeeId6"
                            value={employeeId[6]}
                            onChange={evt => this.handleChange(evt, 6)} />

                    </FormGroup>
                    {
                        employeeIdError && employeeIdError != "" ?
                            <p style={{color: 'red'}}>
                                <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{marginRight: '10px'}} />
                                {employeeIdError}
                            </p>
                        : null
                    }
                </Form>
            </Col>
        </Row>
        <Row>
            <Col style={{textAlign: 'center'}}>
                {
                    employeeIdString.length == 7 ?
                        <Button style={{margin: '30px 0 0 0'}} color={"primary"} size="lg"
                            onClick={(e) => {
                                this.setState({
                                    isLoading : true
                                }, () => {
                                    // send and validate employee id action to send verification code to user for first time
                                    this.props.sendValidateEmpId(this.state.employeeIdString, false);
                                })
                            }}
                            disabled={isLoading}
                        >
                            Next
                            {
                                isLoading ? <Spinner style={{margin: '4px 0px 4px 10px'}} color="light" size="sm" /> : null
                            }
                        </Button>
                    : null
                }
            </Col>
        </Row>
      </div>
    );
  }
}

EmployeedIdForm.propTypes = {
    // triggerNextToSecurityPin: PropTypes.func.isRequired,
    sendValidateEmpId: PropTypes.func.isRequired
}

const mapStatesToProps = state => ({
    triggerNextPinSuccess : state.webRed.triggerNextPinSuccess
})

export default connect( mapStatesToProps, { sendValidateEmpId })( EmployeedIdForm );