import {

	loginCredentialsResponse,
	_getParentQuestions,
	_getQuestions,
	_getSubmitReponse

} from './form_types';


import { accessToken } from './utility';

import axios from 'axios';

// const instance = axios.create({
// 	baseURL: getBaseUrl() + '/'
// });

// Configure the provider with the necessary options.
// const options = {
// 	token: 'abc',
// 	headers: {
// 		'Content-Type': 'application/json'
// 	}
// };
// const options = {};
// instance.interceptors.request.use(tokenProvider(options));

// export const login = (userInfo) => async (dispatch) => {
// 	const res = await axios
// 		.post('https://dev.iponym.com/getdata/signin', userInfo, {
// 			headers: {
// 				'Content-Type': 'application/json'
// 			}
// 		})
// 		.then((res) => {
// 			dispatch({
// 				type: LOG_IN_SUCCESS,
// 				payload: res.status
// 			});
// 			console.log(res);
// 		})
// 		.catch((error) => {
// 			dispatch({
// 				type: LOG_IN_FAILURE,
// 				payload: 'error'
// 			});
// 			console.log(error);
// 		});
// };

export const sendLoginCredentials = (formId, email, f_name, contact) => async (dispatch) => {
	
	axios.get(' https://beta-pagcor-assessment.talentmap.asia/register?form_id='+ formId +'&user_fname=' + f_name + '&contact=' + contact + '&txt_email=' + email+'&access_token='+accessToken())
	.then((res) => {

		if (res.data.message == "Successfully Registered.") { // if success only
			dispatch({
				type: loginCredentialsResponse,
				payload: res
			});
		}
		else {
			dispatch({
				type: loginCredentialsResponse,
				payload: 'error'
			})
		}

	})
	.catch((error) => {
		dispatch({
			type: loginCredentialsResponse,
			payload: 'error'
		});
	});
};
export const registerSupervisor = (formId, email, f_name, contact,callback) => async (dispatch) => {
	
	axios.get(' https://beta-pagcor-assessment.talentmap.asia/register?form_id='+ formId +'&user_fname=' + f_name + '&contact=' + contact + '&txt_email=' + email+'&access_token='+accessToken())
	.then((res) => {

		if (res.data.message == "Successfully Registered.") { // if success only
			callback(res.data)
		}
		else {
			callback(0)
		}

	})
	.catch((error) => {
		callback(0);
	});
};
export const getParetQuestions = (formId, userId, engineId, userType) => async (dispatch) => {

	axios.get(" https://beta-pagcor-assessment.talentmap.asia/formsection/"+ formId +"/1?userid=" + userId + "&engineid=" + engineId + "&user_type=" + userType + "&parent_question=0&parent_answer=0&access_token="+accessToken())
	.then((res) => {

		// if (res.status == 200 && res.statusText == "OK") { // if success only
		
		if (res.status == 200) { // if success only
			dispatch({
				type: _getParentQuestions,
				payload: res
			});
		}
		else {
			dispatch({
				type: _getParentQuestions,
				payload: 'error'
			})
		}

	})
	.catch((error) => {
		console.log(error);
		// dispatch({
		// 	type: loginCredentialsResponse,
		// 	payload: 'error'
		// });
	});
};

export const getChildQuestions = (check, formId, _userId, engineId, userType, questionId, answerId, userToken, fieldType, testId, fieldValue, fieldAnswer,supervisorId=0) => async (dispatch) => {

	if(check){
		axios.get(" https://beta-pagcor-assessment.talentmap.asia/formsection/"+ formId +"/1?userid=" + _userId + "&engineid=" + engineId + "&user_type=" + userType + "&parent_question=" + questionId + "&parent_answer=" + answerId+'&sup_id='+supervisorId+'&access_token='+accessToken())
		.then((res) => {
	
			// if (res.status == 200 && res.statusText == "OK") { // if success only
			
			if (res.status == 200) { // if success only
				var result = {
					questionId : questionId,
					answerId : answerId,
					data : res
				};
				dispatch({
					type: _getQuestions,
					payload: result
				});
			}
			else {
				dispatch({
					type: _getQuestions,
					payload: 'error'
				})
			}
	
		})
		.catch((error) => {
			console.log(error);
			// dispatch({
			// 	type: loginCredentialsResponse,
			// 	payload: 'error'
			// });
		});

		if(fieldType == 2) { // only for type 2 field which is multiple select only one
			axios.get(" https://beta-pagcor-assessment.talentmap.asia/updansreact?type="+ fieldType +"&testid=" + testId + "&quesid="+ questionId +"&ddid=0&ansid="+ answerId +"&value=" + fieldValue + "&answer=" + fieldAnswer + "&isauditor=0&userid="+ _userId+'&sup_id='+supervisorId+'&access_token='+accessToken())
			.then((res) => {
				// console.log(res);
			})
			.catch((error) => {
				console.log(error);
			});
		}

	}
	else{
		var result = {
			questionId : questionId,
			answerId : answerId,
			data : 'remove' + Math.random()
		};
		dispatch({
			type: _getQuestions,
			payload: result
		});
	}


	// var bodyFormData = new FormData();

	// bodyFormData.set('_token', userId);
	// bodyFormData.set('type', fieldType);
	// bodyFormData.set('testid', testId);
	// bodyFormData.set('quesid', questionId);
	// bodyFormData.set('ddid', 0);
	// bodyFormData.set('ansid', answerId);
	// bodyFormData.set('value', fieldValue);
	// bodyFormData.set('answer', fieldAnswer);
	// bodyFormData.set('isauditor', 0);

	// console.log(bodyFormData)

	// console.log(_userId);

	if(fieldType != 2){
		
		axios.get(" https://beta-pagcor-assessment.talentmap.asia/updansreact?type="+ fieldType +"&testid=" + testId + "&quesid="+ questionId +"&ddid=0&ansid="+ answerId +"&value=" + fieldValue + "&answer=" + fieldAnswer + "&isauditor=0&userid="+ _userId+'&access_token='+accessToken())
		.then((res) => {
			// console.log(res);
		})
		.catch((error) => {
			console.log(error);
		});

	}
	
};

export const submitFormNow = (formId, userId) => async (dispatch) => {

	axios.get(" https://beta-pagcor-assessment.talentmap.asia/addnewform?userid="+ userId +"&formid="+ formId+'&access_token='+accessToken())
	.then((res) => {
		
		// if (res.status == 200 && res.statusText == "OK") { // if success only
			dispatch({
				type: _getSubmitReponse,
				payload: "success_" + Math.random()
			});
		// }
		// else {
		// 	dispatch({
		// 		type: _getSubmitReponse,
		// 		payload: 'error'
		// 	})
		// }

	})
	.catch((error) => {
		console.log(error);
	});
};

export const uploadAttachedFile = (questionid, file_engine, userid, form_id) => async (dispatch) => {
	var bodyFormData = new FormData();
	bodyFormData.set('questionid', questionid);
	bodyFormData.set('file_engine', file_engine);
	bodyFormData.set('userid', userid);
	bodyFormData.set('form_id', form_id);
	bodyFormData.set('access_token',accessToken());

	axios.post(' https://beta-pagcor-assessment.talentmap.asia/uploadfilereact', bodyFormData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	.then((res) => {
		console.log(res)
		// if (res.data.status == "success") { // if success only
		// 	dispatch({
		// 		type: getCourseDetailSuccess,
		// 		payload: res.data.result
		// 	});
		// }
		// else {
		// 	dispatch({
		// 		type: getCourseDetailSuccess,
		// 		payload: 'error'
		// 	})
		// }
	})
	.catch((error) => {
		console.log(error)
		// dispatch({
		// 	type: getCourseDetailSuccess,
		// 	payload: 'error'
		// });
	});

};